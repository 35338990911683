import React from "react";

import "./Plan.css";

export default function Plan({
                                 planName,
                                 price,
                                 perUnit,
                                 setPlan,
                                 isSelected,
                                 recommended,
                                 ...props
                             }) {

    var className = "Plan";
    if (isSelected) {
        className += " Selected";
    }

    return (
        <div
            className={className}
            onClick={() => setPlan(planName)}
        >
            <div className="PlanName">{planName}</div>
            <div className="Price">{price}</div>
            <div className="PerUnit">{perUnit}</div>
        </div>
    );
}
