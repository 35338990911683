import React, {useState, useEffect} from "react";
import "./DealsBoard.css";
import {Link} from "react-router-dom";

import CityDataStore from "../util/CityDataStore";
import AirfareDeal from "./AirfareDeal";
import {Image} from "react-bootstrap";

export default function DealsBoard({
                                        ...props
                                    }) {

    const [isLoading, setIsLoading] = useState(true);
    const [dealBoardData, setDealBoardData] = useState(null);

    useEffect(() => {
        async function onLoad() {
            const deals = await props.loadDeals();
            let data = [];
            for (const deal of deals) {
                let airfareDeal = await createAirfareDeal(deal);
                data.push(airfareDeal);
            }
            setDealBoardData(data);
            setIsLoading(false);
        }

        onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function createAirfareDeal(deal) {
        let destinationAirport = deal['destinationAirport'];
        let originAirport = deal['originAirport'];
        let originCity = await CityDataStore.getOriginCity([originAirport]);
        let destinationCity = await CityDataStore.getDestinationCity([destinationAirport]);
        let originCityName = originCity['city'];
        let destinationCityName = destinationCity['city'];

        let imagePath = destinationCityName.replace(/\s/g, "") + ".jpg";
        let image =  new Image().src = `https://flightboard-images.s3.amazonaws.com/${imagePath}`;

        let urlPath = `/deals/${originAirport}/${destinationAirport}`;
        return (
            <Link to={{
                pathname: urlPath,
                state: {
                    fromExampleDeal: props.isExampleDeal
                }
            }}>
                <AirfareDeal
                    key={destinationCityName}
                    originCityName={ originCityName }
                    destinationCityName={destinationCityName}
                    country={destinationCity['country']}
                    countryCode={destinationCity['countryCode']}
                    destinationAirport={destinationAirport}
                    asLowAsPrice={deal['asLowAsPrice']}
                    asHighAsPrice={deal['asHighAsPrice']}
                    normallyPriced={deal['normallyPriced']}
                    dealRate={deal['dealRate']}
                    timeOffered={deal['timeOffered']}
                    tripLengthsOffered={deal['tripLengthsOffered']}
                    lastUpdatedDate={deal['lastUpdated']}
                    numDeals={deal['numDeals']}
                    totalOptions={deal['totalOptions']}
                    totalSearched={deal['totalSearched']}
                    image={ image }
                >
                </AirfareDeal>
            </Link>
        );
    }

    if (isLoading) {
        let skeletonDeal = (
          <AirfareDeal
            isSkeleton={true}
          />
        );
        let deals = [];
        deals.push(skeletonDeal);
        deals.push(skeletonDeal);
        deals.push(skeletonDeal);
        deals.push(skeletonDeal);

        return deals;
    }

    // arrange into rows
    let dealBoardByRow = [];
    let rowSize = 1;
    if (props.width >= 700) {
        rowSize = 2;
    }
    for (let i = 0; i < dealBoardData.length; i = i + rowSize) {
        let rowContents = [];
        for (let j = 0; j < rowSize && j < dealBoardData.length; j++) {
            rowContents.push(dealBoardData[i + j]);
        }
        let row = (
            <div className="DealsRow" key={i}>
                {rowContents}
            </div>
        );

        dealBoardByRow.push(row);
    }
    return dealBoardByRow;
}
