import React from "react";
import {Route, Switch} from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Deals from "./containers/Deals";
import Flights from "./containers/Flights";
import Lander from "./containers/Lander";
import GetStarted from "./containers/GetStarted";
import Login from "./containers/Login";
import Profile from "./containers/Profile";
import Upgrade from "./containers/Upgrade";
import Signup from "./containers/Signup";
import NotFound from "./containers/NotFound";


export default function Routes({appProps}) {
    return (
        <Switch>
            <AppliedRoute path="/" exact component={Lander} appProps={appProps}/>
            <AuthenticatedRoute path="/deals/" exact component={Deals} appProps={appProps}/>
            <AuthenticatedRoute path="/deals/:origin" exact component={Deals} appProps={appProps}/>
            <AuthenticatedRoute path="/deals/:origin/:destination" exact component={Flights} appProps={appProps}/>
            <UnauthenticatedRoute path="/get-started" exact component={GetStarted} appProps={appProps}/>
            <UnauthenticatedRoute path="/signup" exact component={Signup} appProps={appProps}/>
            <AppliedRoute path="/login" exact component={Login} appProps={appProps}/>
            <AuthenticatedRoute path="/profile" exact component={Profile} appProps={appProps}/>
            <AuthenticatedRoute path="/upgrade" exact component={Upgrade} appProps={appProps}/>
            { /* Finally, catch all unmatched routes */}
            <Route component={NotFound}/>
        </Switch>
    );
}
