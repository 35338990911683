import React  from "react";
import emoji from 'node-emoji';
import "./SupportedAirports.css";
import CityDataStore from "../util/CityDataStore";

export default function SupportedAirports() {
    let airportsDiv = [];
    let cities = CityDataStore.getOriginCities();
    for (const [airport, info] of Object.entries(cities)) {
        airportsDiv.push(
            <div className="City">
                <div className="Airport">
                    { emoji.get('round_pushpin') }{ info.city }
                </div>
                <div className="Code">
                    { airport }
                </div>
            </div>
        )
    }

    return (
        <div className="SupportedAirports">
            <p>home airports supported:</p>
            <div className="Airports">
                { airportsDiv }
            </div>
        </div>
    );
}
