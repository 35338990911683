import React, {useEffect, useState} from "react";
import {Elements, StripeProvider} from "react-stripe-elements";
import BillingForm from "../components/BillingForm";
import Plan from "../components/Plan";

import {API} from "aws-amplify";

import config from "../config";
import "./Upgrade.css";

export default function Upgrade(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [planSelected, setPlanSelected] = useState("Annual");
    const [email, setEmail] = useState("Annual");

    function createSubscription(details) {
        return API.post("api", "/upgrade", {
            body: details
        });
    }

    async function loadUser() {
        return API.get("api", "/users");
    }

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated) {
                return;
            }
            try {
                const user = await loadUser();
                setEmail(user.email);
            } catch (e) {
                alert(e);
            }
            setIsLoading(false);
        }

        onLoad();
    }, [props.isAuthenticated]);

    async function handleFormSubmit(paymentMethod) {
        setIsLoading(true);
        try {
            await createSubscription({
                paymentMethod: paymentMethod,
                planSelected: planSelected,
                email: email
            });

            alert("Your card has been charged successfully!");
            props.history.push("/profile");
        } catch (e) {
            alert(e);
            setIsLoading(false);
        }
    }

    return (
        <div className="Upgrade">
            <h1>Upgrade to Premium</h1>
            <div className="Plans">
                <Plan
                    planName="Annual"
                    price="$49"
                    perUnit="per year"
                    setPlan={setPlanSelected}
                    isSelected={planSelected === "Annual"}
                >
                </Plan>
                <Plan
                    planName="Monthly"
                    price="$6"
                    perUnit="per month"
                    setPlan={setPlanSelected}
                    isSelected={planSelected === "Monthly"}
                >
                </Plan>
            </div>
            <StripeProvider apiKey={config.STRIPE_KEY}>
                <Elements>
                    <BillingForm
                        isLoading={isLoading}
                        onSubmit={handleFormSubmit}
                        email={email}
                    />
                </Elements>
            </StripeProvider>
        </div>
    );
}
