import React, {useState} from "react";
import {Link} from "react-router-dom";
import {API, Auth} from "aws-amplify";
import {ControlLabel, FormControl, FormGroup} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import Message from "../components/Message";
import GoogleButton from "../components/GoogleButton";
import ActionButton from "../components/ActionButton";

import {useFormFields} from "../libs/hooksLib";
import "./Login.css";
import LoadScreen from "../components/LoadScreen";

export default function Login(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: ""
    });
    const [message, setMessage] = useState("");
    const [isManualLoginShown, setIsManualLoginShown] = useState(false);

    function validateForm() {
        return fields.email.length > 0 && fields.password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            await Auth.signIn(fields.email, fields.password);
            props.userHasAuthenticated(true);
            props.history.push("/deals");
        } catch (e) {
            var message = (
                <Message
                    text={e.message}
                    isError={true}
                >
                </Message>
            );
            setMessage(message);
            setIsLoading(false);
        }
    }

    async function createUser(user) {
        return API.post("api", "/users", {
            body: user
        });
    }

    async function getUserByEmail(email) {
        return API.get("api", "/usersByEmail/" + email);
    }

    function isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    async function handleGoogleLogin(response) {
        var token = response.tokenId;
        var expires_at = response.tokenObj.expires_at;
        var email = response.profileObj.email;
        const userState = {email};

        // do federated sign in with google response credentials
        const signInResponse = await Auth.federatedSignIn(
            "google",
            {token, expires_at},
            userState
        );

        // try getting user from DB for current federated signed in cognito identity id
        var user = await getUserByEmail(email);
        var signedInIdentityId = signInResponse.data.IdentityId;

        // if user retrieved is non-null
        if (!isEmpty(user)) {
            // if the retrieved user has a different cognito identity id, inform user to log in using normal credentials
            if (user.userId !== signedInIdentityId) {
                var message = (
                    <Message
                        text={'You already have an account. Sign in with your normal credentials'}
                        isError={true}
                    >
                    </Message>
                );
                setMessage(message);
                setIsLoading(false);
                await Auth.signOut();
                props.userHasAuthenticated(false);

                // the user was already created. sign in and redirect to the board
            } else {
                props.userHasAuthenticated(true);
                props.history.push("/deals");
            }

            // if user retrieved is null, create the user and redirect to profile page
        } else {
            var userToCreate = {
                email: email
            }
            await createUser(userToCreate);
            props.userHasAuthenticated(true);
            props.history.push("/profile");
        }
    }

    function showManualLogin() {
        setIsManualLoginShown(true);
    }

    function renderManualLoginForm() {
        if (isManualLoginShown) {
            return (
                <div className="LoginManual">
                    <form onSubmit={handleSubmit}>
                        <FormGroup controlId="email" bsSize="large">
                            <ControlLabel>email</ControlLabel>
                            <FormControl
                                autoFocus
                                type="email"
                                value={fields.email}
                                onChange={handleFieldChange}
                            />
                        </FormGroup>
                        <FormGroup controlId="password" bsSize="large">
                            <ControlLabel>password</ControlLabel>
                            <FormControl
                                type="password"
                                value={fields.password}
                                onChange={handleFieldChange}
                            />
                        </FormGroup>
                        <LoaderButton
                            block
                            type="submit"
                            bsSize="large"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                        >
                            login
                        </LoaderButton>
                    </form>
                    <hr/>
                    <Link to="/signup">
                        <ActionButton
                            text="don't have an account? create one"
                            size="14px"
                        >
                        </ActionButton>
                    </Link>
                </div>
            );
        } else {
            return (
                <div className="LoginManual"
                     onClick={showManualLogin}
                >
                    <Link to="/login">
                        <ActionButton
                            text="log in manually"
                            size="14px"
                        >
                        </ActionButton>
                    </Link>
                </div>
            );
        }
    }

    let accessMessage;
    if (props.location.state && props.location.state.fromExampleDeal) {
        accessMessage = (
            <div className="Notice">
                to view this deal, login to continue
            </div>
        );
    } else if (props.location.state && props.location.state.fromRecentlyBooked) {
        accessMessage = (
            <div className="Notice">
                to see more deals like this deal, login to continue
            </div>
        );
    }
    if (isLoading) {
        return (<LoadScreen
            text={"logging in..."}
        />);
    } else {
        return (
            <div className="Login">
                {accessMessage}
                {message}
                <GoogleButton
                    onLogin={ handleGoogleLogin }
                    setIsLoading={ setIsLoading }
                />
                <p>we use your email only for verification</p>
                <hr/>
                {renderManualLoginForm()}
            </div>
        );
    }
}
