import React, {useEffect, useState} from "react";
import {ControlLabel, FormControl, FormGroup, HelpBlock} from "react-bootstrap";
import {Link} from "react-router-dom";

import LoadScreen from "../components/LoadScreen";
import LoaderButton from "../components/LoaderButton";
import AirportSelector from "../components/AirportSelector";
import Message from "../components/Message";
import ActionButton from "../components/ActionButton";
import dateformat from 'dateformat'
import Toggle from 'react-toggle'

import {API} from "aws-amplify";

import "./Profile.css";
import "react-toggle/style.css" // for ES6 modules

export default function Profile(props) {

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [homeAirports, setHomeAirports] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSavingProfile, setIsSavingProfile] = useState(false);
    const [plan, setPlan] = useState("");
    const [freeTrialExpiry, setFreeTrialExpiry] = useState("");
    const [stripeSubscription, setStripeSubscription] = useState("");
    const [generalSubscription, setGeneralSubscription] = useState(false);
    const [dealsSubscription, setDealsSubscription] = useState(false);

    const [message, setMessage] = useState("");

    async function updateProfile() {
        if (!name || homeAirports.length === 0) {
            var message = (
                <Message
                    text="Fill in all fields"
                    isError={true}
                >
                </Message>
            );
            setMessage(message);
            return;
        }

        setIsSavingProfile(true);
        try {
            let subscriptions = {};
            subscriptions.general = generalSubscription;
            subscriptions.deals = dealsSubscription;
            await API.put("api", "/users", {
                body: {
                    name: name,
                    homeAirports: homeAirports,
                    subscriptions: subscriptions
                }
            });
            await props.fetchProfile();
        } catch (e) {
            alert(e);
        }
        setIsSavingProfile(false);
        setMessage(null);
    }

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated) {
                return;
            }
            try {
                let user = props.user;
                setEmail(user.email);
                setName(user.name);
                setPlan(user.plan);
                setFreeTrialExpiry(user.freeTrialExpiry);
                setStripeSubscription(user.stripeSubscription);
                if (user.subscriptions) {
                    setGeneralSubscription(user.subscriptions.general);
                    setDealsSubscription(user.subscriptions.deals);
                }
                if (user.homeAirports == null) {
                    setHomeAirports([]);
                } else {
                    setHomeAirports(user.homeAirports);
                }
            } catch (e) {
                alert(e);
            }
            setIsLoading(false);
        }

        onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isAuthenticated]);

    if (isLoading) {
        return <LoadScreen></LoadScreen>
    }

    var accountTypeBadge = null;

    if (!plan) {
        var expiryDateString = dateformat(freeTrialExpiry, "mm/dd");
        accountTypeBadge = (
            <div>
                <div className="FreeTrial">
                    Free Trial
                </div>
                <div className="Expiry">
                    expires {expiryDateString}
                </div>
                <div>
                    <Link
                        to={{
                            pathname: "/upgrade",
                        }}
                    >
                        <ActionButton
                            text="upgrade"
                        >
                        </ActionButton>
                    </Link>
                </div>
            </div>
        );
    } else {
        var renewalDate = dateformat(stripeSubscription.current_period_end * 1000, "mm/dd/yy");
        accountTypeBadge = (
            <div>
                <div className="Premium">
                    Premium
                </div>
                <div className="Billing">
                    {plan.billing}
                </div>
                <div className="Expiry">
                    next billing {renewalDate}
                </div>
                <div className="Policy">
                    If you'd like to cancel or change your subscription, <a href="hi@theflightboard.com">please contact
                    us.</a>
                </div>
            </div>
        );
    }

    function handleGeneralSubscription() {
        setGeneralSubscription(!generalSubscription);
    }

    function handleDealsSubscription() {
        setDealsSubscription(!dealsSubscription)
    }

    return (
        <div className="Profile">
            {message}
            <FormGroup
                controlId="emailForm"
                bsSize="large"
            >
                <ControlLabel>Email</ControlLabel>
                <FormControl
                    type="text"
                    placeholder={email}
                    disabled={true}
                />
            </FormGroup>

            <FormGroup
                controlId="nameForm"
                bsSize="large"
            >
                <ControlLabel>Name</ControlLabel>
                <FormControl
                    type="text"
                    placeholder="enter your full name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </FormGroup>

            <FormGroup
                bsSize="large"
            >
                <ControlLabel>Home Airports</ControlLabel>
                <HelpBlock>Choose the cities you're interested in flying out of. We'll send you deals from
                    here. </HelpBlock>
                <HelpBlock><b>Note:</b> We only support some US cities for now. </HelpBlock>

                <AirportSelector
                    currentAirportCodes={ homeAirports }
                    airports={ props.originCities }
                    setHomeAirports={ setHomeAirports }
                >
                </AirportSelector>

            </FormGroup>
            <FormGroup
                controlId="nameForm"
                bsSize="large"
            >
                <ControlLabel>Email Subscriptions</ControlLabel>
                <HelpBlock><b>General: </b>Newsletter, product updates, news. </HelpBlock>
                <label>
                    <Toggle
                        defaultChecked={ generalSubscription }
                        onChange={ handleGeneralSubscription } />
                </label>
                <HelpBlock><b>Daily Deals: </b>Daily deals digest for all of your home airports</HelpBlock>
                <label>
                    <Toggle
                        defaultChecked={ dealsSubscription }
                        onChange={ handleDealsSubscription } />
                </label>
            </FormGroup>

            <LoaderButton
                onClick={updateProfile}
                isLoading={isSavingProfile}
            >
                Save Profile
            </LoaderButton>

            <div className="AccountType">
                Account Type
            </div>
            {accountTypeBadge}
        </div>
    );
}
