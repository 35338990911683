import React, {useEffect, useState} from "react";

import LoadScreen from "../components/LoadScreen";
import ActionButton from "../components/ActionButton";
import {Link} from "react-router-dom";

import "./Deals.css";
import DealsBoard from "../components/DealsBoard";
import {API} from "aws-amplify";

export default function Deals(props) {

    // let i;
    const [isLoading, setIsLoading] = useState(true);
    const [currentAirport, setCurrentAirport] = useState(null);
    // const [filter, setFilter] = useState("Everywhere"); // default
    // const [sort, setSort] = useState("Most Savings"); // default
    //
    // const setSortOptions = ["Most Savings", "Price", "Alphabetical"];
    // const filterOptions = ["Everywhere", "North America", "South America", "Asia", "SE Asia", "Europe", "Africa", "Oceania"]

    async function loadDeals() {
        return API.get("api", "/deals/" + currentAirport);
    }

    useEffect(() => {
        onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isAuthenticated, props.user, currentAirport]);

    async function onLoad() {
        setIsLoading(true);

        // if the user isn't authenticated yet, return, can't do anything yet
        if (!props.isAuthenticated) {
            return;
        }

        // trigger fetch profile if not set (during first login when user is not yet set)
        if (props.user == null) {
            await props.fetchProfile();
            return;
        }

        // if the profile exists and it is valid
        if (!props.user.needToFinishProfile && props.user.isActiveProfile) {
            try {
                let origin = props.match.params.origin;
                if (origin === undefined) {
                    // re-direct to /deals/{origin}, where origin is first home airport
                    origin = props.user.homeAirports[0];
                    props.history.push("/deals/" + origin);
                }
                setCurrentAirport(origin);
            } catch (e) {
                console.log(e);
                alert(e);
            }
        }
        setIsLoading(false);
    }

    let deals = props.deals;
    let airfares = props.airfares;

    if (isLoading || !props.isAuthenticated || props.user == null) {
        if (deals == null || airfares == null) {
            return <LoadScreen
            />
        }
    }
    // free trial has expired, need to upgrade
    if (!props.user.isActiveProfile) {
        return (
            <div className="Home">
                <div className="UpgradeProfile">
                    <h1>Your free trial has expired</h1>
                    <Link to="/upgrade">
                        <ActionButton
                            text="upgrade your account"
                            size={18}
                        >
                        </ActionButton>
                    </Link>
                </div>
            </div>
        );
    }

    // need to finish the profile, cannot load the page
    if (props.user.needToFinishProfile) {
        return (
            <div className="Home">
                <div className="FinishProfile">
                    <h1> Add some home airports to your profile! </h1>
                    <Link to="/profile">
                        <ActionButton
                            text="add airports"
                            size={18}
                        >
                        </ActionButton>
                    </Link>
                </div>
            </div>
        );
    }

    // var rawDealBoard = [];
    // var dealBoard = [];
    // deals.forEach(deal => {
    //     let destinationAirport = deal['destinationAirport'];
    //
    //     // filter by region
    //     if (filter !== 'Everywhere' && props.cities[destinationAirport]['region'] !== filter) {
    //         return;
    //     }
    //
    //     // don't include if airfare exist but city data does not
    //     if (props.cities[destinationAirport] === undefined) {
    //         console.log('destination: ' + destinationAirport + ' does not exist');
    //         return;
    //     }
    //
    //     let cityName = props.cities[destinationAirport]['city'];
    //     let imagePath = cityName.replace(/\s/g, "") + ".jpg";
    //     rawDealBoard.push(
    //         {
    //             imagePath: imagePath,
    //             cityName: cityName,
    //             country: props.cities[destinationAirport]['country'],
    //             countryCode: props.cities[destinationAirport]['countryCode'],
    //             destinationAirport: deal['destinationAirport'],
    //             asLowAsPrice: deal['asLowAsPrice'],
    //             asHighAsPrice: deal['asHighAsPrice'],
    //             normallyPriced: deal['normallyPriced'],
    //             dealRate: deal['dealRate'],
    //             timeOffered: deal['timeOffered'],
    //             tripLengthsOffered: deal['tripLengthsOffered'],
    //             lastUpdatedDate: deal['lastUpdated'],
    //             numDeals: deal['numDeals'],
    //             totalOptions: deal['totalOptions'],
    //             totalSearched: deal['totalSearched']
    //         }
    //     );
    // });
    //
    // // setSort
    // if (sort === "Most Savings") {
    //     rawDealBoard.sort(function (a, b) {
    //         var valueA, valueB;
    //         valueA = a.dealRate;
    //         valueB = b.dealRate;
    //         // increasing deal rate. lower the better.
    //         if (valueA > valueB) {
    //             return 1;
    //         } else if (valueA < valueB) {
    //             return -1;
    //         }
    //         return 0;
    //     });
    // } else if (sort === "Alphabetical") {
    //     rawDealBoard.sort(function (a, b) {
    //         var valueA, valueB;
    //         valueA = a.cityName;
    //         valueB = b.cityName;
    //         if (valueA > valueB) {
    //             return 1;
    //         } else if (valueA < valueB) {
    //             return -1;
    //         }
    //         return 0;
    //     });
    // } else {
    //     rawDealBoard.sort(function (a, b) {
    //         var valueA, valueB;
    //         valueA = a.asLowAsPrice;
    //         valueB = b.asLowAsPrice;
    //         if (valueA > valueB) {
    //             return 1;
    //         } else if (valueA < valueB) {
    //             return -1;
    //         }
    //         return 0;
    //     });
    // }

    return (
        <div className="Home">
            {/*<div className="Header">*/}
            {/*    <h3>home airport</h3>*/}
            {/*    <FilterButtons*/}
            {/*        isLarge={true}*/}
            {/*        options={props.user.homeAirports}*/}
            {/*        updater={handleAirportClick}*/}
            {/*        filter={currentAirport}*/}
            {/*    >*/}
            {/*    </FilterButtons>*/}
            {/*</div>*/}
            {/*<div className="Filters">*/}
            {/*    <h3>sort</h3>*/}
            {/*    <FilterButtons*/}
            {/*        options={setSortOptions}*/}
            {/*        updater={setSort}*/}
            {/*        filter={sort}*/}
            {/*    >*/}
            {/*    </FilterButtons>*/}
            {/*    <h3>location</h3>*/}
            {/*    <FilterButtons*/}
            {/*        options={filterOptions}*/}
            {/*        updater={setFilter}*/}
            {/*        filter={filter}*/}
            {/*    >*/}
            {/*    </FilterButtons>*/}
            {/*</div>*/}
            <DealsBoard
                width={ props.width }
                loadDeals={ loadDeals }
            />
        </div>
    );
}
