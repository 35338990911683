import React from "react";
import "./Footer.css";

export default function Footer() {
    return (
        <div className="Footer">
            <h1>about </h1>
            <h1>privacy policy </h1>
            <h1>refund policy </h1>
            <h1>contact us</h1>
        </div>
    );
}
