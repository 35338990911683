const dev = {
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://api.theflightboard.com/dev"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_a0ihdNf1Y",
        APP_CLIENT_ID: "4dc7mciig0vcm496mf7ujb54h9",
        IDENTITY_POOL_ID: "us-east-1:b13ce022-7ffb-41d2-8ff2-6d536fbd5785"
    },
    STRIPE_KEY: "pk_test_GosPyRu7h5LdESODwywSNrAY00uyXesA73",
    GOOGLE_APP_CLIENT_ID: "292229520207-a7n43qp3quq8e06hjdebvhs8g07hcrrj.apps.googleusercontent.com",
    GOOGLE_ANALYTICS_TRACKING_ID: "UA-156082421-1"
};

const prod = {
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://api.theflightboard.com/prod"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_lbdqncoYa",
        APP_CLIENT_ID: "4btjamd25asnmh81cg3n6mrksb",
        IDENTITY_POOL_ID: "us-east-1:b9e964c1-720d-4c32-919e-47e162e610ae"
    },
    STRIPE_KEY: "pk_test_GosPyRu7h5LdESODwywSNrAY00uyXesA73",
    GOOGLE_APP_CLIENT_ID: "292229520207-ch46m50cgfdp2d75oevrlndgr331k3s2.apps.googleusercontent.com",
    GOOGLE_ANALYTICS_TRACKING_ID: "UA-156082421-1"
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

export default {
    ...config
};
