import React from "react";
import "./Message.css";

export default function Message({
                                    text,
                                    isError,
                                    ...props
                                }) {

    var colorStyle = {
        'backgroundColor': '#FF7E67'
    };

    return (
        <div className="Message" style={colorStyle}>
            {text}
        </div>
    );
}
