import React, { useState } from "react";
import emoji from 'node-emoji';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import "./ShareButton.css";

export default function ShareButton({
    ...props
}) {

    const [isClicked, setIsClicked] = useState(false);
    let link = `https://theflightboard.com/deals/${props.origin}/${props.destination}`;

    function onCopy() {
        setIsClicked(true);
    }

    if (!isClicked) {
        return (
            <CopyToClipboard text={ link }
                             onCopy={ onCopy }>
                <div className="ShareButton">
                    { emoji.get('link') } share
                </div>
            </CopyToClipboard>
        );
    } else {
        return (
            <div className="ShareButtonClicked">
                { emoji.get('link') } link copied
            </div>
        );
    }
}
