import React, {useState} from "react";
import FAQEntry from "./FAQEntry"

import "./FAQ.css";

export default function FAQ({
                                ...props
                            }) {
    const [isHovered, setIsHovered] = useState(false);

    function handleHovered() {
        setIsHovered(!isHovered)
    }

    let questionToAnswerMap = {
        "Why should I use FlightBoard?": "We use automated scrapers to track deals for you. Unlike other comparable products, we don't have a physical team looking for flights for you. Our search capabilities are 100% automated, allowing us to keep our costs down while offering a premium product that continues to evolve and get better for our customers. The power of automation for you is that you don't miss deals. We search literally everything and optimize on things you already look for.",
        "Can't I just use Google Flights?": "We use automated scrapers to track deals for you. Unlike other comparable products, we don't have a physical team looking for flights for you. Our search capabilities are 100% automated, allowing us to keep our costs down while offering a premium product that continues to evolve and get better for our customers. The power of automation for you is that you don't miss deals. We search literally everything and optimize on things you already look for.",
        "How are we different from Scott's Cheap Flights or Jack's Flight Club?": "We use automated scrapers to track deals for you. Unlike other comparable products, we don't have a physical team looking for flights for you. Our search capabilities are 100% automated, allowing us to keep our costs down while offering a premium product that continues to evolve and get better for our customers. The power of automation for you is that you don't miss deals. We search literally everything and optimize on things you already look for.",
        "Do we make money from commission?": "No, we only provide you with the links to book yourself. You must always search and book the flight yourself.  We do NOT make money when you book a flight. We always recommend you book directly through the airline carrier to know you're always getting the real price and not paying any commissions.",
        "Why is FlightBoard not free?": "It costs us to run the servers that search for your flights. It also helps us continue to add new features to constantly improve the product!",
    }
    var content = [];
    for (const [question, answer] of Object.entries(questionToAnswerMap)) {
        content.push(
            <FAQEntry
                question={question}
                answer={answer}
            >
            </FAQEntry>
        );
    }

    return (
        <div className="FAQ"
             onMouseEnter={handleHovered}
             onMouseLeave={handleHovered}
        >
            {content}
        </div>
    );
}
