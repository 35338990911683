import React, {useState} from "react";
import "./AirfareDeal.css";
import flag from 'country-code-emoji';
import emoji from 'node-emoji';
import {Image} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

export default function AirfareDeal({
                                        ...props
                                    }) {

    const [isHovered, setIsHovered] = useState(false);

    function handleHovered() {
        setIsHovered(!isHovered);
    }

    function getHovered(className) {
        if (isHovered) {
            className += " Hovered";
        }
        return className;
    }

    function getDealTextDiv() {
        let dealTextDiv;
        if (props.originCityName != null) {
            dealTextDiv = (
                <div className="Route">
                    <div className="Origin">
                        { props.originCityName }
                    </div>
                    { emoji.get('airplane') }
                    <div className="Destination">
                        { props.destinationCityName }
                    </div>
                </div>
            );
        } else {
            dealTextDiv = (
                <div className="CityName">
                    {props.destinationCityName}
                </div>
            );
        }
        return dealTextDiv;
    }


    let imageDiv = (
        <div className="ImageDiv">
            <Image
                src={props.image}
                rounded
                className={getHovered("Image")}
            >
            </Image>
        </div>
    );

    let dealDiv;
        dealDiv = (
          <div className="DealDiv">
              <div className="DealText">
                  { !props.isSkeleton ? getDealTextDiv() : <Skeleton/> }
              </div>
              <div className="Price">
                  {
                      !props.isSkeleton ?
                          <div className="AsLowAsPrice">
                              {formatCurrency(props.asLowAsPrice)}
                          </div>
                          :
                          <Skeleton height={40} width={100}/>
                  }
                  {
                     !props.isSkeleton ?
                          <div className="Savings">
                              {formatCurrency(props.normallyPriced)}+
                          </div>
                          :
                         <Skeleton width={100}/>
                  }

              </div>
              <div className="TripInfo">
                  {
                      !props.isSkeleton ?
                          <div className="TimeOffered">
                              { props.timeOffered }
                          </div>
                          :
                          <Skeleton width={100}/>
                  }
                  {
                      !props.isSkeleton ?
                          <div className="TripLengthsOffered">
                              { props.tripLengthsOffered + " week trips" }
                          </div>
                          : <Skeleton/>
                  }
                  {
                      !props.isSkeleton ?
                          <div className="NumDeals">
                              { props.numDeals + "+ deals" }
                          </div>
                          :
                          <Skeleton/>
                  }
                  {
                      !props.isSkeleton ?
                          <div className="Flag">
                              { flag(props.countryCode) }
                          </div>
                          :
                          <Skeleton/>
                  }
              </div>

              <div className="LastUpdated">
                  {
                      !props.isSkeleton ?
                          timeSince(props.lastUpdatedDate)
                          :
                          <Skeleton width={30}/>
                  }
              </div>

              <div className="Options">
                  {
                      !props.isSkeleton ?
                          <div className="totalOptions">
                              {props.totalOptions} total options up to {formatCurrency(props.asHighAsPrice)}
                          </div>
                          :
                          <Skeleton width={300}/>
                  }
                  {
                      !props.isSkeleton ?
                          <div className="TotalSearched">
                              searched over {numberWithCommas(props.totalSearched)} flights
                          </div>
                          :
                          <Skeleton/>
                  }
              </div>
          </div>
        );

    return (
        <div className="AirfareDeal"
             onMouseEnter={handleHovered}
             onMouseLeave={handleHovered}
        >
            <div className="ImageDiv">
                { !props.isSkeleton ? imageDiv : <Skeleton/> }
            </div>

            {
                dealDiv
            }
        </div>
    );
}

function formatCurrency(price) {
    return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0}).format(price);
}


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function timeSince(date) {
    let lastUpdatedDate = new Date(date);
    let currentDate = new Date();
    var seconds = Math.floor((currentDate.getTime() - lastUpdatedDate.getTime()) / 1000);
    var interval = Math.floor(seconds / 31536000);

    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + " hours ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + " minutes ago";
    }
    return "a minute ago";
}
