import React, {useState} from "react";
import "./AirfareDetail.css";
import dateformat from 'dateformat'
import emoji from 'node-emoji';

export default function AirfareDetail({
                                          price,
                                          startDate,
                                          endDate,
                                          tripLength,
                                          airlines,
                                          layover,
                                          stops,
                                          link,
                                          ...props
                                      }) {

    startDate = new Date(startDate);
    endDate = new Date(endDate);
    var startDateDay = dateformat(startDate, "ddd");
    var startDateDate = dateformat(startDate, "UTC:mmm d");
    var endDateDay = dateformat(endDate, "ddd");
    var endDateDate = dateformat(endDate, "UTC:mmm d");
    const [isHovered, setIsHovered] = useState(false);

    function getHovered(className) {
        if (isHovered) {
            className += " Hovered";
        }
        return className;
    }

    function handleHover() {
        setIsHovered(!isHovered);
    }

    return (
        <a href={link}
           target="_blank"
           rel="noopener noreferrer"
        >
            <div className="AirfareDetail"
                 onMouseEnter={handleHover}
                 onMouseLeave={handleHover}
            >
                <div className={getHovered("Price")}>
                    {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0
                    }).format(price)}
                </div>
                <div className={getHovered("Link")}>
                    { emoji.get('link') }
                </div>
                <div className="DatesInfo">
                    <div className="TripLength">
                        {tripLength} days
                    </div>
                    <div className="Dates">
                        <div className="StartDate">
                            <div className="Day">
                                {startDateDay}
                            </div>
                            <div className="Date">
                                {startDateDate}
                            </div>
                        </div>
                        <div className="EndDate">
                            <div className="Day">
                                {endDateDay}
                            </div>
                            <div className="Date">
                                {endDateDate}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="TripInfo">
                    <div className="Airlines">
                        {airlines}
                    </div>
                    <div className="StopsLayovers">
                        {stops} - {layover}
                    </div>
                </div>
            </div>
        </a>
    );
}
