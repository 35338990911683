import React from "react";
import {GoogleLogin} from 'react-google-login';
import config from '../config';

import "./GoogleButton.css";

export default function GoogleButton({
    ...props
}) {

    function onSuccess(resp) {
        props.onLogin(resp);
    }

    function onFailure(resp) {
        props.setIsLoading(false);
    }

    function onRequest() {
        props.setIsLoading(true);
    }

    return (
        <div className="GoogleButton">
            <GoogleLogin
                clientId={config.GOOGLE_APP_CLIENT_ID}
                buttonText="Continue with Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                onRequest={onRequest}
                cookiePolicy={'single_host_origin'}
            />
        </div>
    );
}
