import React from "react";
import "./Seperator.css";

export default function Seperator({
                                      ...props
                                  }) {

    return (
        <div className="Seperator"
        />
    );
}
