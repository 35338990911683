import React from "react";
import "./Headline.css";

export default function Headline({
                                     text,
                                     ...props
                                 }) {

    return (
        <div className="Headline">
            {text}
        </div>
    );
}
