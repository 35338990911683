import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";
import {Nav, Navbar, NavItem} from "react-bootstrap";
import Routes from "./Routes";
import {API, Auth} from "aws-amplify";
import ReactGA from 'react-ga';
import {useWindowDimensions} from "../src/libs/hooksLib";
import Footer from "../src/components/Footer";

import "./App.css";

function App(props) {
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const {width} = useWindowDimensions();

    const [user, setUser] = useState(null);

    async function onLoad() {

        // authenticate user, fetch profile data from backend
        try {
            let curUser = await Auth.currentAuthenticatedUser();
            const trackingId = "UA-156082421-1"; // Replace with your Google Analytics tracking ID
            ReactGA.initialize(trackingId);
            ReactGA.set({
                userId: curUser.Id
                // any data that is relevant to the user session
                // that you would like to track with google analytics
            });
            const user = await loadUser();
            user.needToFinishProfile = needToFinishProfile(user);
            user.isActiveProfile = isActiveProfile(user);
            setUser(user);
            userHasAuthenticated(true);
        } catch (e) {
            if (e !== 'not authenticated') {
                console.log('other error occurred');
            } else {
                console.log(e);
            }
        }
        setIsAuthenticating(false);
    }

    useEffect(() => {
        onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchProfile() {
        const user = await loadUser();
        user.needToFinishProfile = needToFinishProfile(user);
        user.isActiveProfile = isActiveProfile(user);
        setUser(user);
    }

    function needToFinishProfile(user) {
        let needToFinishProfile = true;
        if (user.homeAirports != null && user.homeAirports.length > 0) {
            needToFinishProfile = false;
        }
        return needToFinishProfile;
    }

    function isActiveProfile(user) {
        var freeTrialExpiry = user.freeTrialExpiry;
        var plan = user.plan;
        if (plan && plan.type === 'Premium') {
            return true;
        } else if (new Date() <= new Date(freeTrialExpiry)) {
            return true;
        }
        return false;
    }

    async function loadUser() {
        return API.get("api", "/users");
    }

    async function handleLogout() {
        await Auth.signOut();
        userHasAuthenticated(false);
        props.history.push("/login");
    }


    function getNavbarBrand() {
        if (isAuthenticated && props.location.pathname === "/") {
            return (
                <Navbar.Brand>
                    <Link to={"/deals"}><span className="logo">go to <span className="sub">app</span></span></Link>
                </Navbar.Brand>
            );
        } else {
            return (
                <Navbar.Brand>
                    <Link to={"/"}><span className="logo">the<span className="sub">flightboard</span></span></Link>

                </Navbar.Brand>
            );
        }
    }

    return (
        !isAuthenticating &&
        <div className="App container">
            <Navbar fluid collapseOnSelect>
                <Navbar.Header>
                    {getNavbarBrand()}
                    <Navbar.Toggle/>
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav pullRight>
                        {isAuthenticated
                            ? <>
                                <LinkContainer to="/deals">
                                    <NavItem>deals</NavItem>
                                </LinkContainer>
                                <LinkContainer to="/profile">
                                    <NavItem>profile</NavItem>
                                </LinkContainer>
                                <NavItem onClick={handleLogout}>logout</NavItem>
                            </>
                            : <>
                                <LinkContainer to="/login">
                                    <NavItem>login</NavItem>
                                </LinkContainer>
                            </>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Routes appProps={{
                isAuthenticated,
                userHasAuthenticated,
                width,
                user,
                fetchProfile,
            }}/>
            <Footer/>
        </div>
    );
}

export default withRouter(App);
