import React, {useState} from "react";
import Select from 'react-select';
import "./AirportSelector.css";

export default function AirportSelector({
                                            currentAirportCodes,
                                            airports,
                                            setHomeAirports,
                                            ...props
                                        }) {

    // convert to all airports to options
    const options = [];
    for (const airport of Object.values(airports)) {
        let option = {};
        option.value = airport.airport;
        option.label = airport.city + ' - ' + airport.airport;
        options.push(option);
    }

    // convert current home airports to selected options
    const currentOptionsSelected = [];
    if (currentAirportCodes != null) {
        for (var i = 0; i < currentAirportCodes.length; i++) {
            let airportInfo = airports[currentAirportCodes[i]];
            let option = {};
            option.value = airportInfo.airport;
            option.label = airportInfo.city + ' - ' + airportInfo.airport;
            currentOptionsSelected.push(option);
        }
    }
    const [optionsSelected, setOptionsSelected] = useState(currentOptionsSelected);

    function handleChange(optionsSelected) {
        setOptionsSelected(optionsSelected);
        var homeAirports = [];
        if (optionsSelected != null) {
            for (var i = 0; i < optionsSelected.length; i++) {
                homeAirports.push(optionsSelected[i].value);
            }
        }
        setHomeAirports(homeAirports);
    }

    return (
        <Select
            value={optionsSelected}
            onChange={handleChange}
            options={options}
            isMulti={true}
            isSearchable={true}
            placeholder="add airports"
            className="AirportSelector"
        />
    );
}
