import React from "react";
import {Link} from "react-router-dom";
import {Image} from "react-bootstrap";

import Seperator from "../components/Seperator";
import Headline from "../components/Headline";
import ActionButton from "../components/ActionButton";
import FAQ from "../components/FAQ";
import { HashLink } from 'react-router-hash-link';

import emoji from 'node-emoji';
import flag from 'country-code-emoji';

import "./Lander.css";
import logo from "../img/logo.svg";
import {API} from "aws-amplify";
import DealsBoard from "../components/DealsBoard";
import SupportedAirports from "../components/SupportedAirports";

export default function Lander(props) {


    async function loadFreeDeals() {
        return API.get("api", "/dealsFree");
    }

    function renderActionButton() {
        if (props.isAuthenticated) {
            return (
                <Link to="/deals">
                    <ActionButton
                        text="see deals"
                        size="24px"
                    >
                    </ActionButton>
                </Link>
            );
        } else {
            return (
                <Link to="/login">
                    <ActionButton
                        text="get started"
                        size="24px"
                    >
                    </ActionButton>
                </Link>
            );
        }
    }


    return (
        <div className="Lander">
            <div className="MainWelcome">
                <Image src={logo} width="70"/>
                <h1>intelligently searched, premium flight deals</h1>
                <h2>{ emoji.get('desert_island') } save up to $800 on international travel</h2>
                {renderActionButton()}
                <h3>{ emoji.get('point_down')} what is an <HashLink to="#auto-searched-deals"><b>auto-searched</b></HashLink> deal?</h3>
            </div>
            <Seperator/>
            <div className="ExampleDeals">
                <Headline
                    text="current deals"
                >
                </Headline>
                <DealsBoard
                    width={ props.width }
                    loadDeals={ loadFreeDeals }
                    isExampleDeal={ true }
                />
            </div>
            <Seperator/>
            <div className="Section"
                 id="auto-searched-deals"
            >
                <Headline
                    text="auto-searched deals"
                />
                <h3>what if you want to always have access to the best possible travel deals? fares change everyday, so you would need to constantly search for flights and organize them. flightboard automates this and always has the best deals ready for you</h3>
                <div className="Search">
                    <h2>{ emoji.get('mag') } search</h2>
                    <h1>{ emoji.get('desert_island') } search flights to 75+ destinations</h1>
                    <p>for each of your home airports</p>
                    <h1>{ emoji.get('calendar') } search all departure dates for the next 8 months</h1>
                    <p>for each destination</p>
                    <h1>{ emoji.get('mag') } search 1-2 week trip lengths</h1>
                    <p>for each departure date</p>
                </div>
                <div className="Organize">
                    <h2>{ emoji.get('pencil') } organize</h2>
                    <h1>{ emoji.get('chart_with_upwards_trend') } list out all the deals</h1>
                    <p>based on all search results</p>
                    <h1>{ emoji.get('computer') } pick the statistically best deals </h1>
                    <p>using price history and variance</p>
                    <h1>{ emoji.get('muscle') } filter inconvenient deals</h1>
                    <p>avoid deals that require long layovers, mid week travel etc.</p>
                </div>
            </div>
            <Seperator/>
            <div className="HowDoesItWork">
                <Headline
                    text="how does it work?"
                >
                </Headline>
                <div className="HowDoesItWorkRow">
                    <div className="Column">
                        <div className="Number">
                            1
                        </div>
                        <div className="Text">
                            {emoji.get('round_pushpin')}<br/>set your home airports
                        </div>
                    </div>
                    <div className="Column">
                        <div className="Number">
                            2
                        </div>
                        <div className="Text">
                            {emoji.get('calendar')}<br/>browse auto-searched deals
                        </div>
                    </div>
                    <div className="Column">
                        <div className="Number">
                            3
                        </div>
                        <div className="Text">
                            {emoji.get('bell')}<br/>get daily deal emails
                        </div>
                    </div>
                    <div className="Column">
                        <div className="Number">
                            4
                        </div>
                        <div className="Text">
                            {emoji.get('airplane')}<br/>book deals
                        </div>
                    </div>
                </div>
                <p>
                    * You book directly with the airline. We only provide links to the deal. See our <HashLink to="#faq">FAQ</HashLink>.
                </p>
            </div>
            <Seperator/>
            <SupportedAirports
            />
            <Seperator/>
            {/*<div className="Product">*/}
            {/*    <Headline*/}
            {/*        text="Features"*/}
            {/*    >*/}
            {/*    </Headline>*/}
            {/*    <div className="Entry">*/}
            {/*        <h1 className="Header">{emoji.get('chart_with_upwards_trend')} browse the cheapest dates </h1>*/}
            {/*        <p>pre-organized trip timings that are the cheapest, fastest, and include the most weekends. </p>*/}
            {/*    </div>*/}
            {/*    <div className="Entry">*/}
            {/*        <h1 className="Header">{emoji.get('bell')} email notifications</h1>*/}
            {/*        <p>daily deals email summarizing the best deals of the day from all of your home airports</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<Seperator/>*/}

            {/*<div className="Testimonials">*/}
            {/*    <Headline*/}
            {/*        text="recently booked"*/}
            {/*    >*/}
            {/*    </Headline>*/}
            {/*    <br/>*/}
            {/*    <Link to={{*/}
            {/*        pathname: "/login",*/}
            {/*        state: {*/}
            {/*            fromRecentlyBooked: true*/}
            {/*        }*/}
            {/*    }}>*/}
            {/*        <RecentlyBooked*/}
            {/*            origin="Seattle"*/}
            {/*            destination="Dubai"*/}
            {/*            date="March 2020"*/}
            {/*            price="$530"*/}
            {/*            image={ '/CityImages/Dubai.jpg' }*/}
            {/*        />*/}
            {/*    </Link>*/}
            {/*    <Link to={{*/}
            {/*        pathname: "/login",*/}
            {/*        state: {*/}
            {/*            fromRecentlyBooked: true*/}
            {/*        }*/}
            {/*    }}>*/}
            {/*        <RecentlyBooked*/}
            {/*            origin="San Francisco"*/}
            {/*            destination="Paris"*/}
            {/*            date="February 2020"*/}
            {/*            price="$530"*/}
            {/*            image={ '/CityImages/Paris.jpg' }*/}
            {/*        />*/}
            {/*    </Link>*/}
            {/*    <Link to={{*/}
            {/*        pathname: "/login",*/}
            {/*        state: {*/}
            {/*            fromRecentlyBooked: true*/}
            {/*        }*/}
            {/*    }}>*/}
            {/*        <RecentlyBooked*/}
            {/*            origin="Los Angeles"*/}
            {/*            destination="Bali"*/}
            {/*            date="January 2020"*/}
            {/*            price="$673"*/}
            {/*            image={ '/CityImages/Bali.jpg' }*/}
            {/*        />*/}
            {/*    </Link>*/}
            {/*</div>*/}
            {/*<Seperator/>*/}
            <Headline
                text="get started today"
            >
            </Headline>
            <div className="SignUp">
                <a href="/signup">
                    <div className="SignUpButton">
                        <div className="Button">
                            sign up now
                        </div>
                        <div className="Text">
                            enjoy free for 7 days!
                        </div>
                    </div>
                </a>
                <div className="SubText">
                    after that, <b>$49 USD</b> / year
                </div>
            </div>
            <Seperator/>
            <div className="FAQSection" id="faq">
                <Headline
                    text="faq"
                >
                </Headline>
                <FAQ/>
            </div>
            <Seperator/>
            <div className="ComingSoon">
                <Headline
                    text="coming soon"
                >
                </Headline>
                <div className="Features">
                    <h1>{flag('US')} US destinations</h1>
                    <h1>{emoji.get('iphone')} dedicated iPhone / Android apps </h1>
                </div>
            </div>
        </div>
    );
}
