import React, {useState} from "react";
import "./FAQEntry.css";

export default function FAQEntry({
                                     ...props
                                 }) {
    const [isOpened, setIsOpened] = useState(false);

    function handleClick() {
        setIsOpened(!isOpened)
    }

    function answerIfOpen() {
        if (isOpened) {
            return (
                <div className="Answer">
                    {props.answer}
                </div>
            );
        }
    }

    function symbol() {
        if (isOpened) {
            return ("-");
        } else {
            return ("+");
        }
    }

    return (
        <div className="FAQEntry"
             onClick={handleClick}
        >
            <div className="Question">
                {symbol()} {props.question}
            </div>
            {answerIfOpen()}
        </div>
    );
}
