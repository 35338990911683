import React, {useState} from "react";
import {ControlLabel, FormControl, FormGroup} from "react-bootstrap";
import {CardElement, injectStripe} from "react-stripe-elements";
import LoaderButton from "./LoaderButton";
import {useFormFields} from "../libs/hooksLib";
import "./BillingForm.css";

function BillingForm({isLoading, onSubmit, ...props}) {
    const [fields, handleFieldChange] = useFormFields({
        name: "",
    });
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCardComplete, setIsCardComplete] = useState(false);

    isLoading = isProcessing || isLoading;

    function validateForm() {
        return (
            fields.name !== "" &&
            isCardComplete
        );
    }

    async function handleSubmitClick(event) {
        event.preventDefault();
        setIsProcessing(true);
        const {paymentMethod, error} = await props.stripe.createPaymentMethod('card', {
            billing_details: {
                email: props.email,
            }
        });
        if (error) {
            alert(error);
            return;
        }
        setIsProcessing(false);
        onSubmit(paymentMethod.id);
    }

    return (
        <form className="BillingForm" onSubmit={handleSubmitClick}>
            <hr/>
            <FormGroup bsSize="large" controlId="name">
                <ControlLabel>Cardholder's name</ControlLabel>
                <FormControl
                    type="text"
                    value={fields.name}
                    onChange={handleFieldChange}
                    placeholder="Name on the card"
                />
            </FormGroup>
            <ControlLabel>Credit Card Info</ControlLabel>
            <CardElement
                className="card-field"
                onChange={e => setIsCardComplete(e.complete)}
                style={{
                    base: {fontSize: "18px", fontFamily: '"Overpass", sans-serif'}
                }}
            />
            <LoaderButton
                block
                type="submit"
                bsSize="large"
                isLoading={isLoading}
                disabled={!validateForm()}
            >
                Purchase
            </LoaderButton>
        </form>
    );
}

export default injectStripe(BillingForm);
