import React, {useEffect, useState} from "react";

import AirfareDetail from "../components/AirfareDetail";
import FilterButtons from "../components/FilterButtons";
import AirfareDeal from "../components/AirfareDeal";

import "./Flights.css";
import LoadScreen from "../components/LoadScreen";
import ShareButton from "../components/ShareButton";
import {API} from "aws-amplify";
import CityDataStore from "../util/CityDataStore";
import {Image} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function Flights({
                                    ...props
                                }) {
    const [monthFilter, setMonthFilter] = useState("Recommended");
    const [tripLengthFilter, setTripLengthFilter] = useState("Recommended");
    const [airfaresData, setAirfaresData] = useState(null);
    const [deal, setDeal] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const monthFilterOptions = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const tripLengthOptions = ["~ 1 week", "~ 2 weeks"]

    let origin = props.match.params.origin;
    let destination = props.match.params.destination;

    useEffect(() => {
        window.scrollTo(0, 0);
        async function onLoad() {
            let airfaresData = await fetchAirfares(origin, destination);
            let deal = await createAirfareDeal(airfaresData['deal']);
            setAirfaresData(airfaresData);
            setDeal(deal);
            setIsLoading(false);
        }
        onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function fetchAirfares(origin, destination) {
        return API.get("api", "/flights/" + origin + "/" + destination);
    }

    function handleGoBack() {
        props.history.push('/deals');
    }

    function getGoogleLink(startDate, endDate) {
        let url = `https://www.google.com/flights?gl=us#flt=${origin}.${destination}.${startDate}*${destination}.${origin}.${endDate};c:USD;e:1;sd:1;t:f`;
        return url;
    }

    if (isLoading) {
        return (
            <LoadScreen
                text="loading deal..."
            />
        );
    }

    async function createAirfareDeal(deal) {
        let destinationAirport = deal['destinationAirport'];
        let originAirport = deal['originAirport'];
        let originCity = await CityDataStore.getOriginCity([originAirport]);
        let destinationCity = await CityDataStore.getDestinationCity([destinationAirport]);
        let originCityName = originCity['city'];
        let destinationCityName = destinationCity['city'];

        let imagePath = destinationCityName.replace(/\s/g, "") + ".jpg";
        let image =  new Image().src = `https://flightboard-images.s3.amazonaws.com/${imagePath}`;

        let urlPath = `/deals/${originAirport}/${destinationAirport}`;
        return (
            <Link to={{
                pathname: urlPath,
                state: {
                    fromExampleDeal: props.isExampleDeal
                }
            }}>
                <AirfareDeal
                    key={destinationCityName}
                    originCityName={ originCityName }
                    destinationCityName={destinationCityName}
                    country={destinationCity['country']}
                    countryCode={destinationCity['countryCode']}
                    destinationAirport={destinationAirport}
                    asLowAsPrice={deal['asLowAsPrice']}
                    asHighAsPrice={deal['asHighAsPrice']}
                    normallyPriced={deal['normallyPriced']}
                    dealRate={deal['dealRate']}
                    timeOffered={deal['timeOffered']}
                    tripLengthsOffered={deal['tripLengthsOffered']}
                    lastUpdatedDate={deal['lastUpdated']}
                    numDeals={deal['numDeals']}
                    totalOptions={deal['totalOptions']}
                    totalSearched={deal['totalSearched']}
                    image={ image }
                >
                </AirfareDeal>
            </Link>
        );
    }

    let priceToAirfaresMap = airfaresData['priceToAirfaresMap'];

    let airfareDetails = [];
    for (const [price, airfares] of Object.entries(priceToAirfaresMap)) {
        for (let i = 0; i < airfares.length; i++) {
            var airfare = airfares[i];
            var month = new Date(airfare['start_date']).getMonth();
            if (monthFilter !== "Recommended" && month !== monthFilterOptions.indexOf(monthFilter)) {
                continue;
            }
            var tripLength = getTripLength(airfare['start_date'], airfare['end_date']);
            if (tripLengthFilter !== "Recommended") {
                var selectedOption = tripLengthOptions.indexOf(tripLengthFilter);
                if (selectedOption === 0 && tripLength > 10) {
                    continue;
                } else if (selectedOption === 1 && tripLength <= 10) {
                    continue;
                }
            }

            airfareDetails.push(
                <AirfareDetail
                    price={price}
                    startDate={airfare['start_date']}
                    endDate={airfare['end_date']}
                    tripLength={tripLength}
                    airlines={airfare['airlines']}
                    layover={airfare['layover']}
                    stops={airfare['stops']}
                    link={getGoogleLink(airfare['start_date'], airfare['end_date'])}
                >
                </AirfareDetail>
            );
        }
    }

    // arrange month filter options in correct order;
    var today = new Date().getMonth();
    for (let i = 0; i < today; i++) {
        monthFilterOptions.push(monthFilterOptions.shift());
    }

    // add recommended filters
    monthFilterOptions.unshift("Recommended");
    tripLengthOptions.unshift("Recommended");




    return (
        <div className="Flights">
            <div className="Clear"
                 onClick={() => handleGoBack()}
            >
                back
            </div>
            <div className="SelectedDeal">
                { deal }
                <ShareButton
                    origin={ origin }
                    destination={ destination }
                />
            </div>
            <div className="Filters">
                <h3>timing</h3>
                <FilterButtons
                    options={monthFilterOptions}
                    updater={setMonthFilter}
                    filter={monthFilter}
                >
                </FilterButtons>
                <h3>trip length</h3>
                <FilterButtons
                    options={tripLengthOptions}
                    updater={setTripLengthFilter}
                    filter={tripLengthFilter}
                >
                </FilterButtons>
            </div>
            <div className="AirfareDetails">
                { airfareDetails }
            </div>
        </div>
    );
}


function getTripLength(startDate, endDate) {
    var startDate_Date = new Date(startDate);
    var endDate_Date = new Date(endDate);
    var timeDiff = endDate_Date.getTime() - startDate_Date.getTime();
    var tripLength = timeDiff / (1000 * 3600 * 24);
    return tripLength;
}
