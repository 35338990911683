import React from "react";
import "./FilterButtons.css";

export default function FilterButtons({
                                          isLarge,
                                          options,
                                          updater,
                                          filter,
                                          description,
                                          ...props
                                      }) {

    function handleSelect(option) {
        updater(option);
    }

    var filterButtons = [];
    for (let i = 0; i < options.length; i++) {
        let option = options[i];
        let isSelected = (option === filter);
        let className = "FilterButton";
        if (isSelected) {
            className += " Selected"
        }
        if (isLarge) {
            className += " Large"
        }
        filterButtons.push(
            <div className={className}
                 filter={option}
                 key={option}
                 onClick={() => handleSelect(option)}
            >
                {options[i]}
            </div>
        );
    }

    return (
        <div className="FilterButtons"
        >
            {filterButtons}
        </div>
    );
}
