import {API} from "aws-amplify";


async function initializeCities() {
    const [destinationCities, originCities] = await Promise.all([
        loadDestinationCities(),
        loadOriginCities(),
    ]);
    localStorage.setItem("destinationCities", JSON.stringify(destinationCities));
    localStorage.setItem("originCities", JSON.stringify(originCities));
}

async function getDestinationCity(city) {
    let data = localStorage.getItem("destinationCities");
    if (data != null) {
        let json = JSON.parse(data);
        if (json[city] != null) {
            return json[city];
        }
    }
    await initializeCities();
    return JSON.parse(localStorage.getItem("destinationCities"))[city];
}

async function getOriginCity(city) {
    let data = localStorage.getItem("originCities");
    if (data != null) {
        let json = JSON.parse(data);
        if (json[city] != null) {
            return json[city];
        }
    }
    await initializeCities();
    return JSON.parse(localStorage.getItem("originCities"))[city];
}

async function getOriginCities() {
    let data = localStorage.getItem("originCities");
    if (data == null) {
        await initializeCities();
    }
    return JSON.parse(localStorage.getItem("originCities"));
}


async function loadDestinationCities() {
    return API.get("api", "/cities/destinations");
}

async function loadOriginCities() {
    return API.get("api", "/cities/origins");
}


export default {
    getOriginCity,
    getDestinationCity,
    getOriginCities
};
